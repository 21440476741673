import React, { useState, useEffect } from 'react'
import CommonSection from '../shared/CommonSection'
// import tourData from '../assets/data/tours'
import '../styles/tour.css'
import TourCard from '../shared/TourCard'
import SearchBar from '../shared/SearchBar'
import Newsletter from '../shared/Newsletter'
import { Col, Container, Form, Row } from 'reactstrap'
import useFetch from '../hooks/useFetch'
import { BASE_URL } from '../utils/config'
import axios from 'axios'
import { useLocation } from 'react-router-dom';


const ServicesFrom = () => {
    const [pageCount, setPageCount] = useState(0)
    const [page, setPage] = useState(0)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [nationality, setNationality] = useState('');
    const [number, setNumber] = useState('');
    const [description, setDescription] = useState('');

    const location = useLocation();
    const title = location.state.title;

    const nameChange = (event) => {
        setName(event.target.value);
    }

    const emailChange = (event) => {
        setEmail(event.target.value);
    }

    const nationalityChange = (event) => {
        setNationality(event.target.value);
    }

    const numberChange = (event) => {
        setNumber(event.target.value);
    }

    const descriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        sendEmail({
            to: 'kaushalsorathiya777@gmail.com',
            subject: `${title} Service`,
            text: `Hello Quinta,
          
          Name: ${name}
          Email: ${email}
          Nationality: ${nationality}
          Number: ${number}
          Description: ${description}

Thanks ${name}`,
        }).then(response => {
            console.log(response.data);
            // Show a success message to the user
        }).catch(error => {
            console.error(error);
            // Show an error message to the user
        });
    }

    function sendEmail(emailData) {
        return axios.post(`${BASE_URL}/send-email`, emailData);
    }

    return (
        <section>
            <Container>
                {
                    <Row>
                        <Col lg='4'>
                            <form onSubmit={handleSubmit}>
                                <div className="tour__content">

                                    <div className="tour__info">
                                        <h2>{title}</h2>

                                        <div className="tour__extra-details">
                                            <div class="input-group mb-3">
                                                {/* <span class="input-group-text" id="basic-addon1">@</span> */}
                                                <input type="text" class="form-control" placeholder="Name" required aria-label="Name" onChange={nameChange} aria-describedby="basic-addon1" />
                                            </div>
                                        </div>
                                        <div className="tour__extra-details">
                                            <div class="input-group mb-3">
                                                {/* <span class="input-group-text" id="basic-addon1">@</span> */}
                                                <input type="text" class="form-control" placeholder="Email" required aria-label="Email" onChange={emailChange} aria-describedby="basic-addon1" />
                                            </div>
                                        </div>
                                        <div className="tour__extra-details">
                                            <div class="input-group mb-3">
                                                {/* <span class="input-group-text" id="basic-addon1">@</span> */}
                                                <input type="text" class="form-control" placeholder="Nationality" required aria-label="Nationality" onChange={nationalityChange} aria-describedby="basic-addon1" />
                                            </div>
                                        </div>
                                        <div className="tour__extra-details">
                                            <div class="input-group mb-3">
                                                {/* <span class="input-group-text" id="basic-addon1">@</span> */}
                                                <input type="text" class="form-control" placeholder="Number" required aria-label="Number" onChange={numberChange} aria-describedby="basic-addon1" />
                                            </div>
                                        </div>
                                        <div className="tour__extra-details">
                                            <div class="input-group input-group-lg">
                                                {/* <span class="input-group-text" id="basic-addon1">@</span> */}
                                                <textarea type="text" class="form-control" placeholder="Description" aria-label="Description" onChange={descriptionChange} aria-describedby="basic-addon1" />
                                            </div>
                                        </div>
                                        <button className='btn primary__btn' type='submit'>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Col>

                        {/* <Col lg='4'>
                <Booking tour={tour} avgRating={avgRating} />
             </Col> */}
                    </Row>
                }
            </Container>
            {/* <Newsletter /> */}
        </section>
    )
}

export default ServicesFrom