import React, { useState, useEffect } from 'react'
import CommonSection from '../shared/CommonSection'
// import tourData from '../assets/data/tours'
import '../styles/tour.css'
import TourCard from '../shared/TourCard'
import SearchBar from '../shared/SearchBar'
import Newsletter from '../shared/Newsletter'
import { Col, Container, Row } from 'reactstrap'
import useFetch from '../hooks/useFetch'
import { BASE_URL } from '../utils/config'
import { Link } from 'react-router-dom'


const Services = () => {
   const [pageCount, setPageCount] = useState(0)
   const [page, setPage] = useState(0)

   const { data: tours, loading, error } = useFetch(`${BASE_URL}/tours?page=${page}`)
   const { data: tourCount } = useFetch(`${BASE_URL}/tours/search/getTourCount`)

   useEffect(() => {
      const pages = Math.ceil(tourCount / 8)
      setPageCount(pages)
      window.scrollTo(0, 0)
   }, [page, tourCount, tours])

   return (
      <>
         <CommonSection title={"All Services"} />
         {/* <section>
            <Container>
               <Row>
                  <SearchBar />
               </Row>
            </Container>
         </section> */}

         <section>
            <Container>
               <Row>
                  <Col lg='5'>
                     <div className="hero__img-box">
                        <img src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" alt="" />
                     </div>
                  </Col>
                  <Col class="col-md-1 d-flex align-items-center justify-content-center">
                     <div class="vertical-line"></div>
                  </Col>
                  <Col lg='6'>
                     <div className="hero__content">
                        <h1><span className='hightlight'>Services</span></h1>
                        <Link className="services" to='/servicesfrom' state={{ title: "Real Estate" }}><h5 className="services my-3">Real Estate</h5></Link>
                        <Link className="services" to='/servicesfrom' state={{ title: "Investments in Indian Startup ecosystem" }}><h5 className="services my-3">Investments in Indian Startup ecosystem</h5></Link>
                        <Link className="services" to='/servicesfrom' state={{ title: "Debt/Equity/Market/others" }}><h5 className="services my-3">Debt/Equity/Market/others</h5></Link>
                        <Link className="services" to='/servicesfrom' state={{ title: "Critical Services" }}><h5 className="services my-3">Critical Services</h5></Link>
                     </div>
                  </Col>

                  {/* <SearchBar /> */}
               </Row>
            </Container>
         </section>

         {/* <section className='pt-0'>
            <Container>
               {loading && <h4 className='text-center pt-5'>LOADING..........</h4>}
               {error && <h4 className='text-center pt-5'>{error}</h4>}
               {
                  !loading && !error &&
                  <Row>
                     {
                        tours?.map(tour => (<Col lg='3' md='6' sm='6' className='mb-4' key={tour._id}> <TourCard tour={tour} /> </Col>))
                     }

                     <Col lg='12'>
                        <div className="pagination d-flex align-items-center justify-content-center mt-4 gap-3">
                           {[...Array(pageCount).keys()].map(number => (
                              <span key={number} onClick={() => setPage(number)}
                                 className={page === number ? 'active__page' : ''}
                              >
                                 {number + 1}
                              </span>
                           ))}
                        </div>
                     </Col>
                  </Row>
               }
            </Container>
         </section>
         <Newsletter /> */}
      </>
   )
}

export default Services